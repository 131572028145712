import { Controller } from '@hotwired/stimulus'

export default class RedirectQrSupportController extends Controller {
  static targets = ['modal', 'form', 'transit'];

  connect () {
    console.log('connect')
    this.interval = setInterval(() => {
      this.hasTransitTarget && this.transitTarget.reload()
    }, 5000)
  }

  open (e) {
    this.hasModalTarget && this.modalTarget.classList.remove('hidden')
  }

  submitForm (e) {
    if (this.formTarget.checkValidity()) {
      this.formTarget.requestSubmit()
      this.close()
    } else {
      this.formTarget.reportValidity()
    }
  }

  close (e) {
    this.hasModalTarget && this.modalTarget.classList.add('hidden')
  }

  disconnect () {
    clearInterval(this.interval)
  }
}
